#view_list-categories {
    .category-accordion {
        margin-bottom: 10px;

        .accordion-content {
            .table {
                margin-top: 10px;
                border: 1px solid $color-ice;

                thead {
                    tr {
                        th {
                            border: 1px solid $color-ice;
                            font-size: 12px;
                            font-weight: $fw700;
                            color: $color-blue;
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            border: 1px solid $color-ice;
                            font-size: 12px;
                            font-weight: $fw700;
                            color: $color-gray-bolder;

                            .button-table {
                                border: 0;
                                background-color: $color-ice;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-weight: $fw600;
                                font-size: 12px !important;
                                padding: 0.3125rem 0.9375rem;
                                border-radius: 6px;
                                width: auto;

                                span {
                                    color: $color-black;
                                    opacity: 0.9;
                                }

                                svg {
                                    height: 18px;
                                    width: auto;
                                    margin-left: 0.3125rem;

                                    path {
                                        fill: $color-black;
                                        opacity: 0.8;
                                        -webkit-transition: all 0.25s ease-in-out;
                                        -moz-transition: all 0.25s ease-in-out;
                                        -ms-transition: all 0.25s ease-in-out;
                                        -o-transition: all 0.25s ease-in-out;
                                        transition: all 0.25s ease-in-out;
                                    }
                                }

                                &:hover {
                                    background-color: $color-light;

                                    svg {
                                        path {
                                            fill: $color-blue;
                                            opacity: 1;
                                            -webkit-transition: all 0.25s ease-in-out;
                                            -moz-transition: all 0.25s ease-in-out;
                                            -ms-transition: all 0.25s ease-in-out;
                                            -o-transition: all 0.25s ease-in-out;
                                            transition: all 0.25s ease-in-out;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
