//=======================================
// UI * Accordion
//=======================================

._accordion {
  ._accordion-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $color-white;
    border-bottom: 1px solid $color-ice;
    padding: 20px 15px;
    cursor: pointer;
    position: relative;

    ._visible-content {
      color: $color-black;
      font-weight: $fw700;
      width: $percent-100;
    }

    ._chevron-icon {
      svg {
        width: 14px;
        height: auto;

        path {
          fill: $color-black;
          opacity: 0.5;
        }
      }
    }

    ._tag {
      font-size: 10px;
      font-weight: $fw700;
      color: rgba(61, 61, 64, 0.8);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background-color: $color-light;
      border-top: 1px solid $color-ice;
      border-left: 1px solid $color-ice;
      padding: 0.2rem 0.5rem;
      border-top-left-radius: 12px;
      position: absolute;
      right: 0;
      bottom: 0;

      svg {
        width: 8px;
        height: 8px;
        margin-right: 4px;
        position: relative;
        top: 0px;

        path {
          fill: $color-ice-bold;
        }
      }
    }

    ._tag.delivered {
      svg {
        path {
          fill: $color-green;
        }
      }
    }

    ._tag.canceled {
      svg {
        path {
          fill: $color-red;
        }
      }
    }

    ._tag.returned {
      svg {
        path {
          fill: $color-nude;
        }
      }
    }
  }
}

._accordion._inside {
  ._accordion-head {
    padding: 14px 15px;

    ._visible-content {
      font-size: 12px;
    }

    ._chevron-icon {
      svg {
        width: 11px;
      }
    }
  }

  ._accordion-content._inside {
    padding: 12px 15px;

    li {
      list-style: none;
      padding: 0;
      margin-bottom: 15px;
    }
  }
}

//=======================================
// UI * Product Thumbnail
//=======================================

._product-thumbnail {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  ._col {
    display: flex;
    align-items: center;

    &:first-child {
      justify-content: flex-start;
      width: $percent-100;
    }

    &:last-child {
      justify-content: flex-end;
      width: $percent-50;

      button.trash-item {
        max-width: none;
        width: auto;
        background-color: transparent;
        display: flex;

        &:focus,
        &:active {
          background-color: transparent !important;
        }

        svg {
          width: 15px;
          height: auto;

          path {
            fill: $color-gray;
            opacity: 0.6;
          }
        }
      }
    }

    ._image {
      height: 80px;
      width: 80px;
      border-radius: 4px;
      overflow: hidden;

      img {
        width: $percent-100;
        height: auto;
      }
    }

    ._product-info {
      margin-left: 10px;

      .title {
        font-weight: $fw700;
        color: $color-black;
        font-size: 13px;
        margin-bottom: 5px;
      }

      .date {
        font-weight: $fw700;
        color: $color-black;
        font-size: 12px;

        .title {
          font-size: 10px;
          opacity: 0.9;
          margin-bottom: 2px;
        }
      }
    }

    ul._list-content {
      li.flex {
        padding: 0;
        border-bottom: 0;

        ._item-content {
          display: flex;
          align-items: flex-end;
          justify-content: flex-start;

          ._frete {
            font-size: 12px;
            position: relative;
            top: -2px;

            svg {
              width: 18px;
              height: auto;
              position: relative;
              top: -1px;
              margin-right: 4px;

              path {
                fill: $color-black;
                opacity: 0.7;
              }
            }
          }
        }
      }
    }
  }
}

//=======================================
// UI * List Content
//=======================================

._accordion-content._inside,
._list-content {
  li {
    padding: 12px 15px;
    border-bottom: 1px dashed $color-ice;

    ._item-title,
    ._item-content {
      font-weight: $fw700;
      color: $color-black;
      font-family: $font-comfortaa;
    }
    ._item-title {
      font-size: 11px;
      opacity: 0.9;
      margin-bottom: 5px;

      button.button-info {
        background-color: transparent;
        max-width: inherit;
        width: auto;
        padding: 0.15rem;

        &:focus,
        &:active {
          background-color: transparent !important;
        }

        svg {
          width: 13px;
          height: auto;

          path {
            fill: $color-gray;
          }
        }
      }
    }

    ._item-content {
      font-size: 13px;
    }
  }
}

//=======================================
// UI * Timeline Order
//=======================================

._order-timeline {
  ._timeline-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 30px;

    ._col {
      .icon {
        height: 40px;
        width: 40px;
        min-width: 40px;
        max-width: 40px;
        border-radius: 100px;
        background-color: #f0f0f0;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 1;

        svg {
          height: 18px;
          width: auto;

          path {
            fill: $color-black;
            opacity: 0.5;
          }
        }

        &::before {
          content: "";
          display: block;
          height: 30px;
          border-left: 1px dashed #cccccc;
          background-color: transparent;
          position: absolute;
          left: 19px;
          top: 30px;
        }
      }

      .date,
      .hour {
        font-weight: $fw700;
        color: $color-black;
      }

      .date {
        font-size: 13px;
      }

      .hour {
        font-size: 12px;
        opacity: 0.8;
      }

      // First Col
      &:first-child {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;

        .title {
          font-weight: $fw700;
          color: $color-black;
          font-size: 12px;
          margin-left: 12px;
        }

        .icon {
          &::before {
            content: "";
            // display: none;
          }
        }
      }
    }

    // Timeline item
    &:first-child {
      padding-bottom: 0 !important;

      ._col {
        .icon {
          background-color: $color-blue;

          svg {
            path {
              fill: $color-white;
              opacity: 1;
            }
          }

          &::before {
            display: none;
          }
        }

        .title {
          color: $color-blue;
        }
      }
    }

    &:nth-child(2) {
      padding-top: 30px;

      ._col {
        .icon {
          &::after {
            content: "";
            display: block;
            height: 30px;
            border-left: 1px dashed #cccccc;
            background-color: transparent;
            position: absolute;
            left: 19px;
            top: -30px;
          }
        }
      }
    }

    &:last-child {
      padding-bottom: 0 !important;

      ._col {
        .icon {
          &::before {
            display: none;
          }
        }
      }
    }
  }
}

//=======================================
// UI * Price in points
//=======================================

._price {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .ui-item__price {
    display: flex;
    align-items: flex-start;

    .price-tag-fraction,
    .price-tag-symbol {
      font-weight: $fw700;
      color: $color-black;
    }

    .price-tag-fraction {
      font-size: 16px;
    }

    .price-tag-symbol {
      font-size: 10px;
      position: relative;
    }
  }
}

// Type real
._price.real {
  .ui-item__price {
    flex-direction: row-reverse;

    .price-tag-symbol {
      text-transform: uppercase;
      top: 0.185rem;
      margin-right: 0.135rem;
    }
  }
}

// Type points
._price.points {
  .ui-item__price {
    flex-direction: row;

    .price-tag-symbol {
      margin-left: 0.135rem;
    }
  }
}

// Size 2x
._price.real.medium,
._price.points.medium {
  .ui-item__price {
    .price-tag-fraction {
      font-size: 23px;
    }

    .price-tag-symbol {
      font-size: 11px;
    }
  }
}

// Size 2x real
._price.real.medium {
  .ui-item__price {
    .price-tag-symbol {
      top: 0.305rem;
    }
  }
}

// Size 2x points
._price.points.medium {
  .ui-item__price {
    .price-tag-symbol {
      top: 0.205rem;
    }
  }
}

//====================================
// UI * Checkbox large
//====================================

._check-box {
  max-width: 380px;
  width: $percent-100;

  ._check-box--label {
    margin: 0;
    background-color: #f9f9f9;
    width: $percent-100;
    padding: 0.9rem 0.75rem;
    border-radius: 4px;
    cursor: pointer;

    input[type="radio"],
    input[type="checkbox"] {
      display: none;
      visibility: hidden;
    }

    input:checked ~ ._cols > ._col > .icon.mark {
      background-color: $color-blue;

      svg {
        path {
          fill: $color-white;
        }
      }
    }

    ._cols {
      display: flex;
      align-items: center;
      justify-content: space-between;

      ._col {
        &:first-child {
          display: flex;
          align-items: center;
          justify-content: center;

          .icon {
            height: 20px;
            width: 20px;
            min-width: 20px;
            border-radius: 100px;
            background-color: $color-ice-bold;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              width: 10px;
              height: auto;

              path {
                fill: #cccccc;
              }
            }
          }

          .title {
            font-family: $font-comfortaa;
            font-weight: $fw700;
            color: $color-gray;
            font-size: 12px;
            max-width: 290px;
            width: $percent-100;
            margin-left: 10px;
          }
        }

        &:last-child {
          .icon {
            svg {
              height: 14px;
              width: auto;

              path {
                fill: #909093;
              }
            }
          }
        }
      }
    }
  }
}

//====================================
// UI * Status Message
//====================================

._status-message {
  font-size: 12px;
  font-weight: $fw700;
  font-family: $font-comfortaa;
  padding: 0.55rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 380px;
  width: $percent-100;

  .icon {
    margin-right: 10px;

    svg {
      width: 20px;
      height: auto;
      position: relative;
      top: 1px;

      path {
        fill: $color-red;
      }
    }
  }
}

._status-message.error {
  color: $color-red;
  background-color: rgba(194, 64, 63, 0.1);
  border-left: 4px solid rgba(194, 64, 63, 1);

  .icon {
    svg {
      path {
        fill: $color-red;
      }
    }
  }
}

._status-message.success {
  color: $color-white;
  background-color: $color-green;
  border-left: 4px solid $color-green;

  .icon {
    svg {
      path {
        fill: $color-white;
      }
    }
  }
}

//====================================
// UI * Green List
//====================================

.green-list {
  max-width: 380px;
  width: $percent-100;
  margin: auto;

  li {
    margin: 0;
    background-color: $color-white;
    width: $percent-100;
    padding: 0.9rem 0.75rem;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 0;

    &:last-child {
      margin-bottom: 0;
    }

    .icon {
      height: 20px;
      width: 20px;
      min-width: 20px;
      border-radius: 100px;
      background-color: $color-green;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 10px;
        height: auto;

        path {
          fill: $color-white;
        }
      }
    }

    .text {
      font-weight: $fw700;
      color: $color-gray;
      font-size: 12px;
      max-width: 290px;
      width: $percent-100;
      margin-left: 10px;
      font-family: $font-comfortaa;
    }
  }
}

//====================================
// UI * Adversiter Card
//====================================

._advertiser-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;

  ._col {
    display: flex;
    align-items: center;

    &:first-child {
      width: $percent-100;
      justify-content: flex-start;
      padding-right: 10px;
    }

    &:last-child {
      width: 54%;
      justify-content: flex-end;
    }

    ._advertiser-card-logo {
      display: block;
      min-width: 50px;
      max-width: 50px;
      width: 50px;
      height: 50px;
      display: block;
      min-width: 50px;
      max-width: 50px;
      border-radius: 100px;
      overflow: hidden;
      background-color: $color-ice;
      position: relative;

      img {
        height: auto;
        width: 100%;
      }
    }

    ._advertiser-card-title--location {
      font-size: 14px;
      color: $color-black;
      font-weight: $fw700;
      margin-left: 10px;

      .title-location {
        display: block;
        font-size: 11px;
      }
    }

    ._advertiser-card-follow {
      @include media-breakpoint-up(xs) {
        margin-left: 10px;
      }

      @include media-breakpoint-up(sm) {
        margin-left: 88px;
      }

      .btn-follow {
        background-color: $color-blue;
        color: $color-white;
        font-size: 10px;
        padding: 0.219rem 0.889rem;
      }
    }

    ._advertiser-card-redirect {
      margin-left: 20px;

      button {
        padding: 0.25rem;

        svg {
          position: relative;
          top: 2px;
          height: 16px;
          width: auto;
          opacity: 0.6;
        }
      }
    }
  }
}

//====================================
// UI * Select Box - CC - Freight
//====================================

.select-box {
  border: 1px solid $color-ice;

  ._cols {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: {
      top: 0;
      bottom: 0;
      right: 10px;
      left: 0;
    }
    min-height: 55px;

    label {
      width: $percent-100;
      margin: 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;

      ._col {
        &:first-child {
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }

        .icon {
          padding: 0.5rem;
          width: 56px;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            height: 18px;
            width: auto;

            path {
              fill: $color-blue;
            }
          }

          img {
            height: auto;
            width: 26px;
          }
        }

        .text {
          margin-left: 10px;

          ._list-content {
            li {
              padding: 0;
              border: 0;

              ._item-title {
                margin-bottom: 0;
              }
            }
          }
        }

        input[type="radio"],
        input[type="checkbox"] {
          display: none;
        }

        .check {
          height: 18px;
          width: 18px;
          min-width: 18px;
          background-color: $color-white;
          border-radius: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid $color-ice-bold;

          svg {
            height: 10px;
            width: auto;
            opacity: 0;

            path {
              fill: $color-white;
            }
          }
        }

        input:checked ~ .check {
          background-color: $color-blue;
          border: 1px solid $color-blue;

          svg {
            opacity: 1;
          }
        }
      }
    }
  }

  .content {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.select-box._expand {
  ._cols {
    padding-top: 0;
    padding-bottom: 0;

    label {
      ._col {
        &:last-child {
          padding: 0.5rem;
          padding-left: 0;
          padding-right: 0;
          cursor: pointer;
        }

        .icon {
          position: relative;

          .check {
            position: absolute;
            top: 0;
            left: 28px;
            height: 18px;
            width: 18px;
            min-width: 18px;
            background-color: $color-white;
            border-radius: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $color-ice-bold;

            svg {
              height: 10px;
              width: auto;
              opacity: 0;

              path {
                fill: $color-white;
              }
            }
          }

          input:checked ~ .check {
            background-color: $color-blue;
            border: 1px solid $color-blue;

            svg {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

//====================================
// UI * Box Information - Info - Addrs
//====================================

.box-information {
  position: relative;

  button.button-action {
    position: absolute;
    top: 5px;
    right: 0;
    z-index: 1;
    max-width: 42px;

    svg {
      height: 18px;
      width: auto;
      opacity: 0.8;

      path {
        fill: #707070;
      }
    }
  }

  ._set-user-location {
    max-width: inherit;
    margin-bottom: 10px;

    ._wrap-location {
      ._wrap-current-location {
        ._location {
          font-weight: $fw700;
        }
      }
    }
  }
}

//====================================
// UI * Wallet - Activity History
//====================================
._history-block {
  padding: 0.769rem 0.9375rem;
  padding-right: 0;
  margin: 0.9375rem;
  margin-top: 0;
  margin-bottom: 0.625rem;
  -webkit-box-shadow: 0 4px 6px 1px rgba(200, 200, 200, 0.1);
  box-shadow: 0 4px 6px 1px rgba(200, 200, 200, 0.1);
  border: 1px solid #f9f9f9;
  border-radius: 4px;
  font-family: $font-comfortaa;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;

  .punctuated {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 15px;

    .icon {
      position: relative;

      svg {
        height: 20px;
        width: auto;

        path {
          fill: $color-blue;
          opacity: 0.3;
        }
      }

      .check {
        height: 14px;
        width: 14px;
        min-width: 14px;
        border-radius: 100px;
        background-color: #d6e2e4;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -2px;
        right: -5px;

        svg {
          height: 8px;
          width: auto;

          path {
            fill: $color-white;
            opacity: 1;
          }
        }
      }
    }
  }

  .punctuated.active {
    .icon {
      .check {
        background-color: $color-green;
      }
    }
  }

  ._cols {
    align-items: center;
    justify-content: space-between;
    margin-top: 4px;

    ._col {
      .title {
        font-weight: $fw700;
        color: $color-blue;
        font-size: 13px;
      }

      .items {
        margin-top: 5px;
        display: flex;

        .item {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-left: 15px;

          &:first-child {
            margin-left: 0;
          }

          .icon {
            svg {
              height: 16px;
              width: auto;
              position: relative;
              top: 2px;

              path {
                fill: $color-black;
                opacity: 0.5;
              }
            }
          }

          .title {
            font-weight: $fw700;
            color: $color-blue;
            font-size: 11px;
            margin: 0;
            margin-left: 6px;
            position: relative;
            top: 1px;

            ._product-price {
              .ui-item__price {
                .price-tag-fraction {
                  font-size: 14px;
                }

                .price-tag-symbol {
                  top: 0;
                }
              }
            }

            ._product-price.green {
              .ui-item__price {
                .price-tag-fraction,
                .price-tag-symbol {
                  color: $color-green;
                }
              }
            }

            ._product-price.red {
              .ui-item__price {
                .price-tag-fraction,
                .price-tag-symbol {
                  color: $color-red;
                }
              }
            }

            ._product-price.nude {
              .ui-item__price {
                .price-tag-fraction,
                .price-tag-symbol {
                  color: $color-nude;
                }
              }
            }
          }
        }
      }

      .item._description {
        margin-top: 10px;
        .icon {
          span {
            font-weight: $fw700;
            color: $color-blue;
            font-size: 11px;
            margin-left: 6px;
          }
        }

        .title {
          margin-top: 5px;
          font-size: 12px;
          font-weight: $fw400;
          line-height: 1.6;
        }
      }

      .btn {
        padding: 0.469rem 0.9375rem;

        svg {
          height: 23px;
          width: auto;
        }
      }
    }
  }
}

//====================================
// UI * Profile - Address Card
//====================================
.address-card {
  background-color: $color-white;
  border-radius: 5px;
  border: 1px solid #f7f7f7;
  margin-bottom: 10px;

  label {
    margin: 0;
    position: relative;
    width: $percent-100;
    padding: 0.9375rem;
    cursor: pointer;

    input,
    input[type="radio"],
    input[type="checkbox"] {
      display: none !important;
      visibility: hidden !important;
    }

    .action-address {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 10;
      background-color: transparent;
      max-width: 40px;

      svg {
        height: 18px;
        width: auto;
        opacity: 0.8;

        path {
          fill: #707070;
        }
      }
    }

    .mark {
      height: 25px;
      width: 25px;
      min-width: 25px;
      border-radius: 100px;
      background-color: #f1f1f1;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 10px;
      right: 10px;

      svg {
        height: 13px;
        width: auto;

        path {
          fill: #cccccc;
        }
      }
    }

    input:checked ~ .mark {
      background-color: $color-green;

      svg {
        path {
          fill: $color-white;
        }
      }
    }

    .overlay {
      background-color: $color-white;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      border-radius: 5px;
    }

    ._cols {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      position: relative;
      z-index: 1;

      ._col {
        .icon {
          height: 32px;
          width: 32px;
          min-width: 32px;
          background-color: #f6f6f6;
          border-radius: 100px;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            height: 14px;
            width: auto;

            path {
              fill: #cdcdcd;
            }
          }
        }

        .tag {
          font-family: $font-nunito;
          color: #cdcdcd;
          font-weight: 600;
          font-size: 0.75rem;
          background-color: #f9f9f9;
          padding: 0 0.85rem;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          display: inline-block;
          margin-bottom: 14px;
          margin-top: 6px;
        }

        ._address-cep,
        ._full-address {
          opacity: 0.6;
        }

        ._address-cep {
          color: #7d7d81;
          font-size: 14px;
          font-weight: $fw700;
          margin-bottom: 6px;
        }

        ._full-address {
          color: #7d7d81;
          font-size: 11px;
          font-weight: $fw700;
        }

        &:last-child {
          margin-left: 10px;
        }
      }
    }
  }
}

.address-card {
  background-color: #f6f6f6;
  border: 1px solid #f5f5f5;

  input:checked ~ .overlay {
    background-color: #f6f6f6;
    border: 1px solid #f5f5f5;
    -webkit-transition: background 0.2s ease-in-out;
    -moz-transition: background 0.2s ease-in-out;
    -ms-transition: background 0.2s ease-in-out;
    -o-transition: background 0.2s ease-in-out;
    transition: background 0.2s ease-in-out;
  }

  input:checked ~ ._cols {
    ._col {
      .icon {
        background-color: $color-white;

        svg {
          path {
            fill: $color-blue;
          }
        }
      }

      .tag {
        color: $color-blue;
        background-color: #ebebeb;
      }

      ._address-cep,
      ._full-address {
        opacity: 1;
      }
    }
  }
}

.address-card.error {
  border-color: $color-red;
}

.address-card.active {
  background-color: #f6f6f6;
  border: 1px solid #f5f5f5;
  label {
    cursor: default;

    .overlay {
      background-color: #f6f6f6;
      border: 1px solid #f5f5f5;
      -webkit-transition: background 0.2s ease-in-out;
      -moz-transition: background 0.2s ease-in-out;
      -ms-transition: background 0.2s ease-in-out;
      -o-transition: background 0.2s ease-in-out;
      transition: background 0.2s ease-in-out;
    }

    ._cols {
      ._col {
        .icon {
          background-color: $color-white;

          svg {
            path {
              fill: $color-blue;
            }
          }
        }

        .tag {
          color: $color-blue;
          background-color: #ebebeb;
        }

        ._address-cep,
        ._full-address {
          opacity: 1;
        }
      }
    }
  }
}

//====================================
// UI * Error = Nothing To Display
//====================================

._nothing {
  padding: 0.9375rem;

  .icon {
    margin-bottom: 10px;
    text-align: center;

    svg {
      width: auto;
      margin: 0 auto;

      @include media-breakpoint-up(xs) {
        height: 30px;
      }

      @include media-breakpoint-up(md) {
        height: 36px;
      }

      path {
        fill: $color-gray;
        opacity: 0.4;
      }
    }
  }

  .text {
    font-size: 13px;
    font-weight: $fw700;
    color: #a7aaae;
    text-align: center;

    button {
      margin-top: 15px;

      a {
        color: $color-white;
      }
    }
  }
}

//====================================
// UI * Promotions - Stores Cards
//====================================
._ui-card.official-stores-item {
  width: 100%;
  height: $percent-100;
  min-height: 280px;
  border: 1px solid #f4f4f4;
  border-radius: 4px;
  position: relative;

  ._ui-item-wrapper {
    .official-stores-item-background {
      height: 130px;
      overflow: hidden;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      position: relative;

      img {
        display: inline-block;
        width: auto;
        height: 100%;
        margin: 0 auto;
      }

      .promotion-percent {
        height: 65px;
        width: 65px;
        min-width: 65px;
        background-color: $color-red;
        color: $color-white;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-weight: $fw700;
        border-radius: 100%;
        border: 2px dashed $color-blue;
        position: absolute;
        top: 10px;
        right: 10px;

        span {
          display: block;
          font-size: 20px;
          line-height: 1;
          margin-top: 12px;
        }
      }
    }

    .official-stores-item-logo {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      margin-top: -30px;
      @include padding-lt-rt;
      position: relative;
      z-index: map-get($z-index, z-default);

      .logo {
        max-width: 65px;
        height: 65px;
        width: 100%;
        border: 1px solid #f1f1f1;
        border-radius: 6px;
        background-color: $color-white;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        img {
          height: auto;
          width: $percent-100;
        }
      }

      .title-wrapper {
        display: block;
        margin-left: 15px;
        position: relative;
        top: -5px;
      }
    }

    .official-stores-item-items {
      margin-top: 15px;
      @include padding-lt-rt;
      padding-bottom: 15px;
      text-align: center;

      .text {
        min-height: 40px;

        p {
          font-weight: $fw700;
          color: $color-black;
          font-size: 13px;
          text-align: left;
        }
      }

      button.see-offer {
        margin-top: 25px;
        background-color: $color-red;

        &:active,
        &:focus {
          background-color: $color-red !important;
        }

        a {
          color: $color-white;
        }
      }
    }

    .official-stores-action {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 15px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      max-width: 100px;
      text-align: center;
      color: $color-black;
      font-size: 13px;
      opacity: 0.8;

      svg {
        width: 7px;
        height: 13px;
        margin-left: 5px;
        position: relative;
        top: -1px;
      }
    }
  }
}

//====================================
// UI * All = Page Headline
//====================================

#page-section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 0;
  border-bottom: 1px solid #f9f9f9;
  margin-bottom: 30px;

  .icon {
    svg {
      path {
        fill: $color-blue;
      }
    }
  }

  .title {
    font-size: 16px;
    font-weight: $fw700;
    color: $color-blue;
  }
}

//====================================
// UI * Category Accordion
//====================================

.category-accordion {
  background-color: $color-white;
  border: 0.0625rem solid $color-ice;
  border-radius: 0.375rem;

  .accordion-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: $percent-100;
    padding: 0.9375rem;
    cursor: pointer;

    .accortion-title {
      font-size: 0.875rem;
      font-weight: $fw700;
      color: $color-blue;
      margin: 0;
    }

    .toggle-icon {
      svg {
        width: 26px;
        height: auto;

        path {
          fill: $color-gray-bolder;
          opacity: 0.8;
        }
      }
    }
  }

  .accordion-access {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .accordion-content {
    padding: 0.9375rem;
    border-top: 1px solid $color-light;

    .Filters {
      .title {
        font-size: 12px;
      }
    }
  }
}

//=============================================
// !!IMPORTANT - UI * Dialog - Mobile User Menu
//=============================================
#DrawerCategories {
  .wrap-back-list {
    position: relative;
    width: $percent-100;
    display: block;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      height: $percent-100;
      width: 50px;
      background: rgb(246, 246, 249);
      background: -moz-linear-gradient(
        90deg,
        rgba(246, 246, 249, 0) 0%,
        rgba(249, 249, 251, 0.7) 43%,
        rgba(255, 255, 255, 0.7959558823529411) 61%
      );
      background: -webkit-linear-gradient(
        90deg,
        rgba(246, 246, 249, 0) 0%,
        rgba(249, 249, 251, 0.7) 43%,
        rgba(255, 255, 255, 0.7959558823529411) 61%
      );
      background: linear-gradient(
        90deg,
        rgba(246, 246, 249, 0) 0%,
        rgba(249, 249, 251, 0.7) 43%,
        rgba(255, 255, 255, 0.7959558823529411) 61%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f6f6f9",endColorstr="#ffffff",GradientType=1);
    }

    .back-list {
      background-color: $color-ice;
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      width: $percent-100;
      overflow: scroll;
      position: relative;

      .back-list-item {
        border: 0;
        padding: 0.75rem 0.4688rem 0.75rem 0.4688rem;
        background-color: $color-ice;
        display: flex;
        align-items: center;
        justify-content: center;

        &:first-child {
          padding-left: 0.9375rem;
        }

        .text {
          font-weight: $fw700;
          color: $color-black;
          font-size: 0.6875rem;
          margin-left: 0.625rem;
          opacity: 0.9;
          text-align: left;
          white-space: nowrap;
        }

        .icon {
          svg {
            height: 13px;
            width: auto;

            path {
              fill: $color-black;
              opacity: 0.5;
            }
          }
        }
      }
    }
  }

  .menu-list {
    li {
      border-bottom: 1px solid rgba(204, 204, 204, 0.2);

      &:first-child {
        border-top: 0;
      }

      label {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: $percent-100;
        padding: 0.75rem 0.9375rem;

        input[type="radio"],
        input[type="checkbox"] {
          display: none !important;
          visibility: hidden !important;
        }

        .text {
          font-weight: $fw700;
          color: $color-black;
          font-size: 12px;
        }

        .icon {
          svg {
            height: 13px;
            width: auto;

            path {
              fill: $color-black;
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
}

.MuiDialog-root.DialogStyled {

  .MuiDialog-container {

    .MuiPaper-root {

      .MuiDialogTitle-root {

        @include media-breakpoint-up(md) {
          border-bottom: 1px solid $color-light;
        }

        .MuiTypography-root {

          ._view-head-content {

            ._title-view {

              font-size: 16px;
              font-weight: $fw700;
              color: $color-black;
              font-family: $font-nunito;
              margin-top: 0 !important;
            }
          }

          button {

            svg {
              height: 18px;
            }
          }
        }
      }
    }
  }
}

.MuiDialog-root.DialogStyled.DialogWithBackground {

  .MuiDialog-container {

    .MuiPaper-root {

      .MuiDialogContent-root {
        background-color: $color-light;
      }
    }
  }
}