#view_account-details {

    padding-top: 76px;
    background-color: $color-white;



    ._fluid-container {

        .loader {
            border: 13px solid #f3f3f3; /* Light grey */
            border-top: 13px solid #14233e; /* Blue */
            border-radius: 50%;
            width: 60px;
            height: 60px;
            animation: spin 2s linear infinite;
        }
          
        @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }

        .centralize-loader{
            display: flex;
            justify-content: center;
            align-items: center;
        }

        ._page-section {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 15px 0;
            border-bottom: 1px solid #f9f9f9;
            margin-bottom: 30px;

            .icon {

                svg  {
                    
                    path {
                        fill: $color-blue;
                    }
                }
            }

            .title {
                font-size: 16px;
                font-weight: $fw700;
                color: $color-blue;
            }
        }

        ._wrap-filter-activity {
            
            h2.title {
                font-size: 13px;
                font-weight: $fw700;
                color: $color-blue;
            }

        }

        ._wrap-table {

            h2.title {
                font-size: 13px;
                font-weight: $fw700;
                color: $color-blue;
            }
        }
    }

    .pagination-style {
        display: flex;
        align-items: center;
        justify-content: center;

        padding-top: 20px;
        padding-bottom: 30px;
    }
}