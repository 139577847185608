.select-product-container {
  display: flex;

  .search-products-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
  }
  ._title-select-product {
    font-family: $font-comfortaa;
    font-weight: $fw500;
    color: $color-gray-bolder;
    text-align: center;
    font-size: 14px;
  }

  .alredy-select-container {
    padding-left: 10px;
    min-width: 350px;
    max-width: 350px;
  }

  .alredy-select-list {
    width: 100%;
    position: relative;
    overflow: auto;
  }

}

.GridProducts {
  .select-product-container {

    border-bottom: 1px solid #f1f1f1;
    .search-products-container {

      ._wrap-load-more {
        margin: 30px 0;
        text-align: center;

        .btn.btn-red {
          color: #fff !important;
        }
      }
    }
  }
}

.GridProduct {
  ._wrap-form {
    ._form {

      #view_create-ad {

        .MuiGrid-root.WrapButtonSubmit {
          text-align: left;

          ._content-title {
            margin: 0;
            text-align: left;
            margin-bottom: 0.25rem;
          }
        }
      }
    }
  }
}