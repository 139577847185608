
#view_login {

  height: 100vh;
  
  @include media-breakpoint-up(xs) {
    background-color: $color-white;
  }
  
  @include media-breakpoint-up(md) {
    background-color: #f9f9f9;
  }

  ._wrap-auth-screen {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .auth-screen {
      max-width: 450px;
      width: 100%;
      padding: 50px 30px;
      background-color: $color-white;
      border-radius: 3px;

      .logo {

        width: $percent-100;
        text-align: center;
        margin-bottom: 30px;

        img {
          width: 36px;
          height: auto;
        }

      }

      ._auth-screen-title {
        margin-bottom: 30px;
        justify-content: center;
      }

      ._wrap-form {
        form {
          ._checkbox.form-check {
            margin-top: 15px;
          }

          .form-group-button {
            margin-top: 15px;
          }

          ._wrap-recovery {
            display: flex;
            align-items: center;
            justify-content: space-between;

            ._checkbox.form-check {
              .form-check-label {
                ._alt-text {
                  @include media-breakpoint-up(xs) {
                    font-size: 11px;
                  }

                  @include media-breakpoint-up(md) {
                    font-size: 12px;
                  }
                }
              }
            }

            ._link-recovery-password {
              font-weight: $fw700;
              color: $color-nude;
              position: relative;
			  top: 6px;
			  cursor: pointer;

              @include media-breakpoint-up(xs) {
                font-size: 11px;
              }

              @include media-breakpoint-up(md) {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}

._wrap-auth-screen {
  @include media-breakpoint-up(sm) {
    padding: 100px 0 200px 0;
  }

  .auth-screen {
    @include media-breakpoint-up(sm) {
      width: $percent-100;
      border-color: $color-ice;
      border-width: 1px;
      border-style: solid;
      -webkit-box-shadow: 0 4px 14px 4px rgba(180, 180, 180, 0.1);
      box-shadow: 0 4px 14px 4px rgba(180, 180, 180, 0.1);
    }
  }
}

._have-account {
  margin-top: 25px;
}
