._wrap-header {
    
    ._header {

        background-color: $color-white;
        position: relative;
        z-index: map-get($z-index, header);
        
        @include media-breakpoint-up(xs) {
            -webkit-box-shadow: 0 4px 8px 4px rgba(0,0,0,1);
            box-shadow: 0 4px 8px 4px rgba(0,0,0,1);
            padding: {
                top: 20px;
                bottom: 20px;
            }
        }
        
        @include media-breakpoint-up(xs) {
            -webkit-box-shadow: 0 4px 8px 4px rgba(0,0,0,0.04);
            box-shadow: 0 4px 8px 4px rgba(0,0,0,0.04);
        }

        ._med-container {

            .cols {

                display: flex;
                width: $percent-100;

                @include media-breakpoint-up(xs) {
                    flex-direction: column;
                }

                @include media-breakpoint-up(lg) {
                    flex-direction: row;
                }

                .col-left {

                    @include media-breakpoint-up(xs) {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: $percent-100;
                        margin-bottom: 20px;
                    }

                    @include media-breakpoint-up(lg) {
                        width: auto;
                        margin-bottom: 0;
                    }

                    .icon-nav-mobile {
                        max-width: 102px;
                        width: $percent-100;
                        button {

                            @include mobile-button-icon;

                            span {
                                display: block;
                                height: 2px;
                                width: 16px;
                                background-color: $color-blue;
                                margin-bottom: 3px;
                                border-radius: 12px;
                                position: relative;

                                &:nth-child(2) {
                                    left: 3px;
                                }
                            }

                        }

                        @include media-breakpoint-up(xs) {
                            display: block;
                        }

                        @include media-breakpoint-up(lg) {
                            display: none;
                        }

                    }

                    .logo {

                        a {
                            display: inline-block;
                            img {

                                @include media-breakpoint-up(xs) {
                                    width: 28px;
                                }

                            }
                        }

                    }

                    ._wrap-points-access {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        max-width: 102px;
                        width: $percent-100;

                    .it-works-menu {

                        button,
                        a {

                            @include mobile-button-icon;

                            svg {
                                width: 20px;
                                height: 20px;
                            }

                        }

                        @include media-breakpoint-up(xs) {
                            display: none;
                        }

                        @include media-breakpoint-up(lg) {
                            display: none;
                        }

                    }

                    ._my-points {
                        font-weight: $fw700;
                        font-size: 8px;
                        color: $color-gray;
                        padding: 0 0;

                        @include media-breakpoint-up(xs) {
                            display: block //block;
                        }

                        @include media-breakpoint-up(lg) {
                            display: none;
                        }

                        ._product-price {
                            .ui-item__price {
                                .price-tag-fraction {
                                    font-size: 13px;
                                }
                            }
                        }
                    }

                    ._login {

                        @include media-breakpoint-up(xs) {
                            display: block;
                        }

                        @include media-breakpoint-up(lg) {
                            display: none;
                        }

                        a.btn {
                            color: $color-black;
                            font-weight: $fw700;
                            font-size: 13px;
                        }
                    }
                    }

                }

                .col-right {

                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    @include media-breakpoint-up(lg) {
                        width: $percent-100;
                    }

                    .categories-menu,
                    .messages-menu,
                    .user-menu,
                    .it-works-menu,
                    .announce-button {

                        padding: 0 0.806rem;

                        @include media-breakpoint-up(xs) {
                            display: none;
                        }

                        @include media-breakpoint-up(lg) {
                            display: block;
                        }

                    }

                    .categories-menu,
                    .it-works-menu,
                    .announce-button {

                        a, button {
                            font-size: 13px;
                            font-weight: $fw700;
                        }

                    }

                    .search-menu {

                        @include media-breakpoint-up(xs) {
                            width: 100%;
                        }

                        @include media-breakpoint-up(lg) {
                            width: auto;
                        }

                        form {
                            padding-bottom: 0;
                        .form-group.search {

                            @include media-breakpoint-up(xs) {
                                max-width: inherit;   
                                min-width: inherit;   
                                width: 100%;
                            }

                            @include media-breakpoint-up(lg) {

                                max-width: 360px;
                                min-width: 360px;

                            }

                            @include media-breakpoint-up(xl) {

                                max-width: 420px;
                                min-width: 420px;

                            }

                        }
                        }

                    }
                    
                    .categories-menu,
                    .user-menu {

                        position: relative;

                    }
                    
                    .categories-menu {
                        
                        ._dropdown-menu._positions {
                            top: 40px;
                            left: 0;
                            
                            &::before {
                                left: 50px;
                            }
                            
                            &::after {
                                left: 50px;
                            }
                        }
                        
                    }

                    .messages-menu {
                        button {
                            padding: 0;
                        }
                    }

                    .it-works-menu > a > svg {
                        width: 20px;
                        height: auto;
                    }

                    .it-works-menu {

                        border-left: 1px solid #f1f1f1;
                        ​a.btn {
                            display: block;
                        svg {
                            width: 20px;
                            height: auto;
                            display: block;
                            @include media-breakpoint-up(xs) {
                                display: block;
                            }

                            @include media-breakpoint-up(lg) {
                                // display: none;
                            }
                        }
                        }
                    }
                        
                    ._my-points {
                        font-weight: $fw700;
                        font-size: 10px;
                        color: $color-gray;
                        border-left: 1px solid #f1f1f1;
                        padding: 0 0.806rem;

                        @include media-breakpoint-up(xs) {
                            display: none;
                        }

                        @include media-breakpoint-up(lg) {
                            display: block;
                        }

                        ._product-price {
                            .ui-item__price {
                                .price-tag-fraction {
                                    font-size: 14px;
                                }
                            }
                        }
                    }

                    ._login {

                        @include media-breakpoint-up(xs) {
                            display: none;
                        }

                        @include media-breakpoint-up(lg) {
                            display: block;
                        }

                        a.btn {
                            color: $color-black;
                            font-weight: $fw700;
                            font-size: 13px;
                        }

                    }

                    .announce-button {

                        .btn {
                            padding: 0.719rem 1.264rem;
                        }

                    }

                }

            }

        }

    }
    
}