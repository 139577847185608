#view_scambers {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 76px;
    padding-bottom: 40px;
    background-color: $color-white;

    ._fluid-container {

        ._page-section {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 15px 0;
            border-bottom: 1px solid #f9f9f9;
            margin-bottom: 30px;

            .icon {

                svg  {
                    
                    path {
                        fill: $color-blue;
                    }
                }
            }

            .title {
                font-size: 16px;
                font-weight: $fw700;
                color: $color-blue;
            }
        }

        ._wrap-filter-activity {
            
            h2.title {
                font-size: 13px;
                font-weight: $fw700;
                color: $color-blue;
            }

        }

        .table {
            margin: 0;
            
            tbody {
                margin: 0;

                tr {

                    td {
                        button._btn-view-points.btn.btn-primary {
                            background-color: transparent !important;
                            text-align: left;
                            display: flex;
                            align-items: center;
                            
                            span {
                                display: inline-block;
                                color: $color-black;
                                padding-right: 10px;
                                min-width: 65px;
                            }

                            .plus {
                                height: 28px;
                                width: 28px;
                                border-radius: 100px;
                                background-color: $color-ice;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }

                            &:hover {

                                .plus {
                                    background-color: $color-white;
                                }
                            }
                        }
                    }

                    &:hover {

                        td {

                            button._btn-view-points.btn.btn-primary {
                                &:hover {

                                    .plus {
                                        background-color: $color-white;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}