//====================================
// Global Imports
//====================================

//// 1 - Import bootstrap framework
@import '1-frameworks/index';
//
//// 2 - Import base style, custom variables and mixins
@import '2-base/index.scss';
//
//// 3 - Import modules
@import '3-modules/index.scss';
//
//// 4 - Import layout  styles
@import '4-layout/index.scss';
