$z-index: (
    z-default                   : 98,
    header                      : 99,
	column-mobile		        : 123,
    aside-menu                  : 130,
    dropdown-user-menu          : 131,
    messenger                   : 132,
    messengerProposalMob        : 90,
    messengerProposal           : 139,
    messengerDropdown           : 140,
    completeRegistration        : 1001,
	fixed-menu			        : 999,
	filter			            : 999,
);